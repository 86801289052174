import React from "react";
import Login from "./pages/LoginWithNumber/index.js";
import LoginEmail from "./pages/LoginWithEmail/index.js";
import Drives from "./pages/Drives";
import Pictures from "./pages/Pictures";
import NotFound from "./pages/Not_Found/index";
import Home from "./pages/Home_Page";
import LoginToken from './pages/LoginToken';
import Danfes from './pages/Danfes';
import Debits from './pages/Debits';
import NotasFiscais from './pages/NotasFiscais';
import Visits from './pages/Visits';
import Projects from './pages/Projects';
import Receipts from './pages/Receipts';
import Invoices from './pages/Invoices';
import Balance from "./pages/Balance";
import Contracts from './pages/Contracts';
import Moves from './pages/Moves';
import UserInfos from './pages/UserInfos';
import Map from './pages/Map';
import Media from './pages/Media';
import Catalog from './pages/Catalog';
import NewCatalog from './pages/NewCatalog';
import LoadUnloadReport from './pages/LoadUnloadReport';
import Notifications from './pages/Notifications';
import ContractDetails from './pages/ContractDetails';
import FreightCalculator from './pages/FreightCalculator';
import DrivesMoves from './pages/DrivesMoves';
import Dashboard from './pages/Dashboard';
import CheckList from './pages/CheckList';
import UserRegistrationForm from "./pages/UserRegistration";
import { isAuthenticated } from "./auth";
import { BrowserRouter, Route, Switch, Redirect, Router } from "react-router-dom";
import { history } from './helper/history'
import Showroom from "./pages/Showroom/index.js";
import CommercialVisit from "./pages/CommercialVisits/index.js";
import DatatableExample from './pages/DatatableExample';
import ApexChartsExample from './pages/ApexChartsExample';
import ProgressExample from './pages/ProgressExample';
import AgendamentosExample from './pages/AgendamentosExample';
import ProgressBarExample from './pages/ProgressBarExample';

// ROTAS QUE O USUÁRIO SÓ PODERÁ ACESSAR CASO ESTEJA LOGADO
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{ pathname: "/login", state: { from: props.location } }}
                />
            )
        }
    />
);

// DENIFIÇÃO DE ROTAS
const Routes = () => (
    <Router history={history}>
        <Switch>
            <Route
                exact
                path="/(login|)"
                render={props =>
                    isAuthenticated()
                        ? (<Redirect to={{ pathname: '/home' }} />)
                        : (<Login {...props} />)
                }
            // render={props =>
            //     isAuthenticated()
            //         ? (<Redirect to={{ pathname: '/dashboard' }} />)
            //         : (<Login {...props} />)
            // }
            />
            <Route exact path="/login-email" component={() => <LoginEmail />} />
            <Route exact path="/token" component={() => <LoginToken />} />
            <Route exact path="/user-registration" component={() => <UserRegistrationForm />} />
            <PrivateRoute exact path="/old-home" component={() => <Home />} />
            <PrivateRoute exact path="/home" component={() => <Dashboard />} />
            dashboard
            {/* <PrivateRoute exact path="/details" component={() => <ContractDetails />} /> */}
            <PrivateRoute exact path="/user" component={() => <UserInfos />} />
            <PrivateRoute exact path="/map" component={() => <Map />} />
            <PrivateRoute exact path="/datatable-example" component={() => <DatatableExample />} />
            <PrivateRoute exact path="/agendamentos-example" component={() => <AgendamentosExample />} />
            <PrivateRoute exact path="/apexcharts-example" component={() => <ApexChartsExample />} />
            <PrivateRoute exact path="/progressbar-example" component={() => <ProgressBarExample />} />
            <PrivateRoute exact path="/progress-example" component={() => <ProgressExample />} />
            <PrivateRoute exact path="/media" component={() => <Media />} />
            {/* <PrivateRoute exact path="/catalog" component={() => <Catalog />} /> */}
            <PrivateRoute exact path="/catalog" component={() => <NewCatalog />} />
            {/* <PrivateRoute exact path="/new-catalog" component={() => <NewCatalog />} /> */}
            <PrivateRoute exact path="/load-unload-report" component={() => <LoadUnloadReport />} />
            <PrivateRoute exact path="/showroom" component={() => <Showroom />} />
            {/* <PrivateRoute exact path="/notifications" component={() => <Notifications />} /> */}
            <PrivateRoute exact path="/drives" component={() => <Drives />} />
            <PrivateRoute exact path="/drives-moves" component={() => <DrivesMoves />} />
            <PrivateRoute exact path="/dashboard" component={() => <Dashboard />} />
            <PrivateRoute exact path="/freight-calculator" component={() => <FreightCalculator />} />
            <PrivateRoute exact path="/checklist" component={() => <CheckList />} />
            <PrivateRoute exact path="/moves" component={() => <Moves />} />
            <PrivateRoute exact path="/debits" component={() => <Debits />} />
            <PrivateRoute exact path="/notas-fiscais" component={() => <NotasFiscais />} />
            <PrivateRoute exact path="/contracts" component={() => <Contracts />} />
            <PrivateRoute exact path="/invoices" component={() => <Invoices />} />
            <PrivateRoute exact path="/balance" component={() => <Balance />} />
            <PrivateRoute exact path="/receipts" component={() => <Receipts />} />
            <PrivateRoute exact path="/projects" component={() => <Projects />} />
            <PrivateRoute exact path="/visits" component={() => <Visits />} />
            <PrivateRoute exact path="/danfes" component={() => <Danfes />} />
            <PrivateRoute exact path="/pictures" component={() => <Pictures />} />
            <PrivateRoute exact path="/commercialvisit" component={() => <CommercialVisit />} />
            <Route component={() => <NotFound />} />
        </Switch>
    </Router>
);

export default Routes;
