import api from '../Services/api';
import FileSaver from 'file-saver';
import moment from 'moment';

export default {
    currenzialize: (value) => {
        let formated = parseFloat(value)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let [int, decimal] = formated.split('.');
        let newformated = `${int.replace(',', '.')},${decimal}`
        return newformated;
    },
    currenzializeWithoutDecimals: (value) => {
        let formated = parseFloat(value)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let [int, decimal] = formated.split('.');
        let newformated = `${int.replace(',', '.')}`
        return newformated;
    },
    queryParams: () => {
        let { href } = window.location
        let query = href.split('?').pop()
        let params = query.split('&');

        let callback = {};
        for(let param of params) {
            callback[param.split('=')[0]] = param.split('=')[1];
        }

        return callback;
    },
    phoneMask: (value) => {
        let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,5})(\d{0,4})/);
        return `+${x[1]} (${x[2]}) ${x[3]}-${x[4]}`
    },
    remap: (value, low1, high1, low2, high2) => {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    },
    removeDuplicates: (originalArray, prop) => {
        var newArray = [];
        var lookupObject  = {};
   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }
   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    },
    downloadFile: (url, filename) => {
        api.get(`${url}`, {
            responseType: 'arraybuffer',
        })
        .then(response => {
            if(response.status == 200) {
                const blob = new Blob([response.data], {
                    type: 'application/pdf',
                });
                FileSaver.saveAs(blob, `${filename}.pdf`);
                // console.log(response.data);
            }
        })
    },    
    itemsList: async (contractId) => {
        let response = await api.get(`/api/contrato/${contractId}/saldo/${moment().format('YYYY-MM-DD')}`);
        if(response.data) {
            return response.data;
        }
    },
    freightCalculation: () => {
        
    },
    base64ToArrayBuffer: (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
}