import React from 'react';
import './styles.css';

const Background = (props) => {
    return (
    <div className="background-app">
        <img src={props.imagePath} />
    </div>
    )
}

export default Background;