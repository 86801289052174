import React, { Component } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    CardHeader,
    CardBody,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import Background from "../../components/background/Background";
import InputMask from "react-input-mask";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import axios from "axios";
import api from "../../Services/api";
import _api from "../../Services/_api";
import "./styles.css";
import { history } from "../../helper/history";

import ReactGA from "react-ga";

class UserRegistrationForm extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            name: '',
            email: '',
            phone: '',
            contract: ''
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/RegistroTelefone");
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    clearForm = () => {
        this.setState({
            name: '',
            email: '',
            phone: ''
        })
    }

    handleSubmit = async e => {
        e.preventDefault();

        try {
            this.setState({ loading: true });

            const params = {
                subject: "SH - Solicitação de cadastro",
                body: {
                    name: this.state.name,
                    contact: this.state.phone,
                    email: this.state.email,
                    contract: this.state.contract
                },
                template: 'registerUser',
                to: "bernardo.fernandes@sh.com.br"
            };
            await _api.post("/newemailwoauth", params)

            this.clearForm();
            this.setState({ loading: false })
            NotificationManager.success(
                `Sua solicitação de cadastro foi enviada com sucesso!`,
                'Sucesso',
                4000
            )
        } catch (err) {
            this.setState({ loading: false })
            NotificationManager.error(
                "Erro ao enviar, tente novamente mais tarde",
                'Erro',
                4000
            )
            console.log(err)
            throw err
        }
    };

    render() {
        return (
            <>
                <NotificationContainer />
                <Background imagePath="https://kroonar.s3.amazonaws.com/sh_bg.gif" />
                <div className="login-container">
                    <Container>
                        <Row>
                            <Col
                                sm={{ size: 12 }}
                                md={{ size: 6, offset: 3 }}
                                xl={{ size: 4, offset: 4 }}
                            >
                                <Card>
                                    <CardHeader
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <img src={"./images/logo.svg"} />
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={this.handleSubmit}>
                                            <InputGroup>
                                                <input
                                                    className="form-control"
                                                    value={this.state.name}
                                                    required
                                                    onChange={e => {
                                                        this.setState({
                                                            name: e.target.value
                                                        });
                                                    }}
                                                    type="tel"
                                                    placeholder="Informe seu nome"
                                                    style={{
                                                        letterSpacing: "1px"
                                                    }}
                                                />
                                            </InputGroup>
                                            <br />
                                            <input
                                                className="form-control"
                                                value={this.state.contract}
                                                required
                                                onChange={e => {
                                                    this.setState({
                                                        contract: e.target.value
                                                    });
                                                }}
                                                placeholder="Insira o contrato"
                                                style={{
                                                    letterSpacing: "1px"
                                                }}
                                            />
                                            <br />
                                            <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend">
                                                    <img
                                                        src={
                                                            "./images/icon_login_number.png"
                                                        }
                                                        style={{
                                                            width: 50,
                                                            height: "100%"
                                                        }}
                                                    />
                                                </InputGroupAddon> */}
                                                <InputMask
                                                    mask={`+99 (99) 99999-9999`}
                                                    className="form-control"
                                                    value={this.state.phone}
                                                    required
                                                    onChange={e => {
                                                        this.setState({
                                                            phone: e.target.value.replace(
                                                                /\D/g,
                                                                ""
                                                            )
                                                        });
                                                    }}
                                                    type="tel"
                                                    placeholder="Insira seu número"
                                                    style={{
                                                        letterSpacing: "1px"
                                                    }}
                                                />
                                            </InputGroup>
                                            <br />
                                            <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend">
                                                    <img
                                                        src={
                                                            "./images/email_icon.png"
                                                        }
                                                        style={{
                                                            width: 50,
                                                            height: "100%"
                                                        }}
                                                    />
                                                </InputGroupAddon> */}
                                                <input
                                                    className="form-control"
                                                    value={this.state.email}
                                                    required
                                                    onChange={e => {
                                                        this.setState({
                                                            email: e.target.value
                                                        });
                                                    }}
                                                    type="email"
                                                    placeholder="Insira seu email"
                                                    style={{
                                                        letterSpacing: "1px"
                                                    }}
                                                />
                                            </InputGroup>
                                            <div className="login-links">
                                                <Link
                                                    to="/login"
                                                    className="login-link"
                                                    block
                                                >
                                                    FAZER LOGIN
                                                </Link>
                                            </div>

                                            <div
                                                className="button-Container"
                                            >
                                                <Button
                                                    type="submit"
                                                    block
                                                    disabled={this.state.loading}
                                                >
                                                    ENVIAR SOLICITAÇÃO
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
export default UserRegistrationForm;