import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import InputMask from 'react-input-mask'
import _api from '../../Services/_api'
import { button as buttonTheme } from '../../themes'
import './styles.css'

const ModalEditProfile = ({ modal, setModal, onSave, title, type, userData }) => {

    const [valueInput, setValueInput] = useState('');
    const [inputIsValid, setInputIsValid] = useState(true);
    const [loading, setLoading] = useState(false);

    const inputValidate = valueInputText => {
        switch (type) {
            case 'name':
                if (valueInputText.length > 0) setInputIsValid(true)
                else setInputIsValid(false)
                break
            case 'email':
                const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (regexEmail.test(valueInputText)) setInputIsValid(true)
                else setInputIsValid(false)
                break
            case 'contact':
                const regexContact = /(\+\d{2}\s)(\(?\d{2}\)?\s)(\d{4,5}\-\d{4})/g
                if (regexContact.test(valueInputText)) setInputIsValid(true)
                else setInputIsValid(false)
                break
        }
    }

    const submit = async () => {
        try {
            setLoading(true)
            const { nome, email, telefone } = userData
            const body = { name: nome, email, contact: telefone, field: '', newValue: valueInput }
            if (type === 'name') body.field = 'Nome'
            else if (type === 'email') body.field = 'E-mail'
            else if (type === 'contact') body.field = 'Telefone'
            const params = {
                subject: "SH - Solicitação para alterar dado de perfil",
                body,
                template: 'updateProfile',
                to: 'digital@sh.com.br' // Assistente de vendas
            }
            await _api.post("/newemail", params)
            setLoading(false)
            NotificationManager.success(
                `Sua solicitação para editar o '${body.field}' foi enviada com sucesso`,
                'Sucesso',
                4000
            )
            closeModal()
        } catch (err) {
            setLoading(false)
            NotificationManager.error(
                "Erro ao enviar, tente novamente mais tarde",
                'Erro',
                4000
            )
            throw err
        }
    }

    const closeModal = () => {
        setValueInput('')
        setModal(false)
    }

    return (
        <Modal className='modal-edit-profile' centered={true} isOpen={modal} toggle={() => setModal}>
            <NotificationContainer />
            <ModalBody>
                <span className='title'>Editar {title}</span>
                <InputMask
                    mask={type === 'contact' ? '+99 (99) 99999-9999' : null}
                    invalid={inputIsValid ? false : true}
                    value={valueInput}
                    onChange={input => {
                        setValueInput(input.target.value)
                        inputValidate(input.target.value)
                    }}
                    className='input'
                    placeholder={`Insira seu novo ${title.toLowerCase()}`} />
                {
                    inputIsValid ? null : <span className='errorText'>Campo inválido</span>
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    // color='danger'
                    // style={buttonTheme}
                    className='btn-hollow'
                    onClick={closeModal}>
                    Cancelar
                </Button>
                {
                    loading
                        ? <Spinner className='spinner' />
                        : <Button
                            onClickCapture={async () => await submit()}
                            //   color='yellow'
                            //   style={buttonTheme}
                            className='btn-hollow'
                            disabled={!inputIsValid} onClick={() => onSave(valueInput)}>
                            Enviar
                        </Button>
                }
            </ModalFooter>
        </Modal>
    )

}

export default ModalEditProfile