import React, { Component } from "react";
import Header from "../../components/header";
import BodyNotFound from "../../components/body_not_found";
import { isAuthenticated } from "../../auth";

import ReactGA from "react-ga";

class NotFound extends Component {
    constructor() {
        super();
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/Not_found");
    }
    render() {
        return (
            <div>
                {isAuthenticated() ? (
                    <div>
                        <Header />
                    </div>
                ) : null}
                <div>
                    <BodyNotFound />
                </div>
            </div>
        );
    }
}
export default NotFound;
