import React from 'react'
import './styles.css'

const PageTitle = ({ title, imgPath }) => {
    return (
        <>
            <div
                className='row'
            >
                <div
                    className='col-md-12'
                >
                    <div
                        className='title-container'
                    >
                        <img
                            src={imgPath}
                        />
                        {(title)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageTitle