import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import {
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
// import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import "./styles.css";
import PDFSizedContainer from "../../components/pdf-viewer";
import ReactGA from "react-ga";

export default class Projects extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).idPropostaContrato,
            projects: [],

            selectedProject: [],
            infoModal: false,
            loading: false,
            fileModal: '',
            mobile: false,
            windowHeight: 0
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/projetos");
    }

    toggleInfos = project => {
        this.setState({
            selectedProject: project,
            infoModal: !this.state.infoModal
        });
    };

    getContractProjects = async id => {
        this.setState({ loading: true })
        const promises = [
            api.get(`/api/contrato/${id}/projetos`) // 123 = ervId
        ];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];
                switch (x) {
                    case 0:
                        this.setState({
                            projects: resp.data.sort(function (a, b) {
                                let aArr = a.nome.split('-')
                                var aR;
                                var aExtra;
                                if (aArr[aArr.length - 1].includes('R')) {
                                    aR = aArr[aArr.length - 1];
                                } else if (aArr[aArr.length - 2].includes('R')) {
                                    aR = aArr[aArr.length - 2];
                                    aExtra = aArr[aArr.length - 1];
                                } else {
                                    return -1 || b.nome.localeCompare(a.nome);
                                }

                                let bArr = b.nome.split('-')
                                var bR;
                                var bExtra;
                                if (bArr[bArr.length - 1].includes('R')) {
                                    bR = bArr[bArr.length - 1];
                                } else {
                                    bR = bArr[bArr.length - 2];
                                    bExtra = bArr[bArr.length - 1];
                                }

                                if (aExtra !== undefined && bExtra !== undefined) {
                                    return aR.localeCompare(bR) || aExtra.localeCompare(bExtra) || b.nome.localeCompare(a.nome);
                                } else if (aExtra !== undefined) {
                                    return aR.localeCompare(bR) || 1 || b.nome.localeCompare(a.nome);
                                } else if (bExtra !== undefined) {
                                    return aR.localeCompare(bR) || -1 || b.nome.localeCompare(a.nome);
                                }

                                return aR.localeCompare(bR) || b.nome.localeCompare(a.nome);
                            })
                        });
                        this.setState({ loading: false })
                        break;
                }
            }
        });
    };

    openModalFile = project => {
        var url = `/api/contrato/${this.state.contractId}/projeto/${project.nome}`;
        api.get(`${url}`, {
            responseType: 'arraybuffer',
        })
            .then(response => {
                if (response.status == 200) {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    });
                    const fileURL = URL.createObjectURL(blob);
                    this.setState({
                        fileModal: fileURL,
                        selectedProject: project
                    });
                }
            })
    };

    resize() {
        this.setState({ windowHeight: window.innerHeight });
    }

    renderData = () => {
        if (this.state.projects.length === 0)
            return (
                <div className="no-items">
                    <strong>Itens não disponíveis</strong>
                </div>
            )

        return this.state.projects.map((value, key) => {
            return (
                <tr key={value.idProjeto}>
                    {/* <td>{value.nome}</td> */}
                    <td>{`${value.nome} (${value.descRevisao})`}</td>
                    <td>
                        {/* <svg
                            onClick={() => {
                                this.toggleInfos(value);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                        </svg> */}
                        <img
                            style={{
                                marginRight: "12px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                this.toggleInfos(value);
                            }}
                            src={"./icons/icone_opcoes.svg"}
                        />
                        {/* <img 
                            src={"./images/view-file.png"} 
                            onClick={() => {
                                this.openModalFile(value);
                            }} 
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"                            
                        /> */}
                        {/* <svg
                            onClick={() => {
                                util.downloadFile(
                                    `/api/contrato/${this.state.contractId}/projeto/${value.nome}`,
                                    `${value.nome} (${value.descRevisao})`
                                );
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg> */}
                        <span title="Arquivos">
                            <img
                                style={{
                                    marginRight: "12px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    util.downloadFile(
                                        `/api/contrato/${this.state.contractId}/projeto/${value.nome}`,
                                        `${value.nome} (${value.descRevisao})`
                                    );
                                }}
                                src={"./icons/icone_download.svg"}
                            />
                        </span>
                    </td>
                </tr>
            );
        });
    };

    componentDidMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        this.getContractProjects(this.state.contractId);
        // document.title = `${document.title} - Notas de Débito`
    };

    componentWillMount = () => {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    render() {
        return (
            <>
                <div className="projects-container">
                    <NotificationContainer />
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header />
                    {/* <Breadcrumbs
                        items={[
                            {
                                title: "Home",
                                link: `/home?contract=${this.state.contractId}`
                            },
                            { title: "Projetos" }
                        ]}
                    />
                    <ContractsPicker /> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Sidebar />
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <PageTitle
                                title='Projetos'
                                imgPath='./icons/sidebar/menu_projetos.svg'
                            />
                            <div
                                className="page-body"
                            >
                                <Row>

                                    {!this.state.loading ? (
                                        <Col xs={{ size: 12 }}>
                                            <div class="alert alert-info w-100">
                                                <h4 class="alert-heading">Atenção:</h4>
                                                <p class="font-weight-normal">O arquivo dos projetos só será disponibilizado para download após 24h do lançamento do mesmo no sistema.</p>
                                                <hr />
                                                {/* <p class="mb-0 font-weight-normal">Aguarde um retorno em breve!</p> */}
                                            </div>
                                            <Table size="sm" striped borderless>
                                                <thead>
                                                    <tr>
                                                        <th>PROJETO</th>
                                                        <th>OPÇÕES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{this.renderData()}</tbody>
                                            </Table>
                                        </Col>
                                    ) : (
                                        <Col
                                            md={{ size: 12 }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            {/* Carregando{" "} */}
                                            <Spinner style={{ marginLeft: "1rem", color: "#7c7c7c" }} />
                                        </Col>
                                    )}
                                </Row>
                                <Modal centered isOpen={this.state.infoModal}>
                                    <ModalHeader>INFORMAÇÕES DO PROJETO</ModalHeader>
                                    <ModalBody className="options-info-modal">
                                        <div className="info-box">
                                            <ul>
                                                <li>
                                                    <span className="label">PROJETO</span>
                                                    <br />
                                                    <span className="value">
                                                        {this.state.selectedProject.nome ||
                                                            "-"}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="label">TRECHO</span>
                                                    <br />
                                                    <span className="value">
                                                        {this.state.selectedProject
                                                            .descRevisao || "-"}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="label">
                                                        PROJETISTA
                                                    </span>
                                                    <br />
                                                    <span className="value">
                                                        {this.state.selectedProject
                                                            .nmFuncionario || "-"}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            className="btn-hollow"
                                            onClick={() => {
                                                this.toggleInfos([]);
                                            }}
                                        >
                                            CONCLUIR
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Modal className="options-file-modal" isOpen={this.state.fileModal !== ''}>
                                    <ModalHeader>DOCUMENTO DO PROJETO</ModalHeader>
                                    <ModalBody>
                                        {this.state.fileModal !== '' ?
                                            <PDFSizedContainer url={this.state.fileModal} fileName={this.state.selectedProject.nome} />
                                            :
                                            ''
                                        }
                                        {/* <object data={this.state.fileModal} type="application/pdf" frameborder="0" style={{width: '100%', height: '100%'}}>
    <p>Oops! O seu navegador não suporta arquivos PDF!</p>
    <p><a href={this.state.fileModal} download={this.state.selectedProject.nome}>Fazer download</a></p>
</object>                                                             */}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            className="btn-hollow"
                                            onClick={() => {
                                                this.setState({ fileModal: '', selectedProject: [] });
                                            }}
                                        >
                                            FECHAR
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <a href="/commercialvisit">
                                            <img
                                                style={{
                                                    margin: "auto",
                                                    marginTop: "55px", width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                src={"./images/banners/agendamento.png"}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
