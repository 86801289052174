import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Header from "../../components/header";
import MediaBox from "../../components/media-box";
import MediaLink from "../../components/media-link";
import { Redirect, Link } from "react-router-dom";
import "./styles.css";

import ReactGA from "react-ga";

const Media = props => {
    ReactGA.initialize("UA-173154606-1");
    ReactGA.pageview("/media");
    return (
        <>
            <div className="media-container">
                <Header activeTab="media" />
                <div className="media">
                    <div className="col">
                        <MediaLink
                            link="https://www.sh.com.br/"
                            title="Site"
                            icon="at"
                            bgColor="#e42c2a"
                            textColor="white"
                        />
                        <MediaLink
                            link="https://www.sh.com.br/blog/"
                            title="Blog"
                            icon="rss"
                            bgColor="#e42c2a"
                            textColor="white"
                        />
                        <MediaLink
                            link="https://www.facebook.com/shbrasil"
                            title="Facebook"
                            icon="facebook"
                            bgColor="#e42c2a"
                            textColor="white"
                        />
                        <MediaLink
                            link="https://www.linkedin.com/company/sh-formas-ltda/"
                            title="LinkedIn"
                            icon="linkedin"
                            bgColor="#e42c2a"
                            textColor="white"
                        />
                        <MediaLink
                            link="https://www.instagram.com/shbrasil/"
                            title="Instagram"
                            icon="instagram"
                            bgColor="#e42c2a"
                            textColor="white"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Media;
