import
React
    from "react";
import './styles.css';
import ProgressBar from "@ramonak/react-progress-bar";

export default function ProgressBarExample() {
    return (
        <>
            <div className="progressbar">
                <div className="saldo-title">
                    Saldo da obra
                </div>
                {/*https://www.npmjs.com/package/@ramonak/react-progress-bar*/}
                <ProgressBar
                    completed={60}
                    height="28px"
                    bgColor="#F6CD45"
                    baseBgColor="#F0F0F0"
                    labelColor="#FFF"
                    animateOnRender={true}
                    labelSize="11px"
                    transitionDuration='3s'
                />

                <div className="saldo-weight">
                    1.952,00 KG
                </div>
            </div>
        </>
    );
};