import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import {
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    Card,
    CardTitle,
    CardText
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
// import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import moment from "moment";
import "moment/locale/pt-br";

import "./styles.css";

import ReactGA from "react-ga";

export default class Receipts extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).idPropostaContrato,
            receipts: [],
            files: [],
            selectedReceipt: null,

            loading: true,

            activeTab: "1"
        };

        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/recibos");
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    };

    toggleFileModal = receipt => {
        this.setState({
            selectedReceipt: receipt
        });
    };

    getContractReceipts = async id => {
        const promises = [
            api.get(`/api/contrato/${id}/recibosentrada`),
            api.get(`/api/Contrato/${id}/Lista/RecibosEntrada`) // 123 = ervId
        ];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];
                switch (x) {
                    case 0:
                        this.setState({ receipts: resp.data });
                        break;
                    case 1:
                        this.setState({ files: resp.data });
                        break;
                }
            }
        });
    };

    renderData = () => {
        return this.state.receipts.map((value, key) => {
            return (
                <tr key={value.nrReciboEntrada}>
                    <td>{value.nrReciboEntrada}</td>
                    <td>{moment(value.dtEmissaoReciboEntrada).format("L")}</td>
                    <td>R$ {util.currenzialize(value.vlReciboEntrada)}</td>
                    <td>{util.currenzializeWithoutDecimals(value.vlPeso)}</td>
                    <td>
                        {/* <svg
                            onClick={() => {
                                this.toggleFileModal(value);
                            }}
                            fill="#000000"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24">
                            <path d="M 30.398438 2 L 7 2 L 7 48 L 43 48 L 43 14.601563 Z M 15 28 L 31 28 L 31 30 L 15 30 Z M 35 36 L 15 36 L 15 34 L 35 34 Z M 35 24 L 15 24 L 15 22 L 35 22 Z M 30 15 L 30 4.398438 L 40.601563 15 Z" />
                        </svg> */}
                        <span title="Arquivos">
                            <img
                                style={{
                                    marginRight: "12px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    this.toggleFileModal(value);
                                }}
                                src={"./icons/icone_download.svg"}
                            />
                        </span>
                    </td>
                </tr>
            );
        });
    };

    renderFiles = (files) => {
        return files.map((value, i) => {
            return (
                <tr key={i}>
                    <td>{value.arquivoSemExtensao}</td>
                    <td>{moment(value.data).format("L")}</td>
                    <td>
                        <svg
                            style={{ 'cursor': 'pointer' }}
                            onClick={() => {
                                util.downloadFile(
                                    `/api/contrato/${this.state.contractId}/reciboentrada/${value.arquivoSemExtensao}`,
                                    value.arquivoSemExtensao
                                );
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </td>
                </tr>
            );
        });
    };

    componentDidMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        this.getContractReceipts(this.state.contractId).then(() => this.setState({ loading: false }));
        // document.title = `${document.title} - Notas de Débito`
    };

    render() {
        return (
            <>
                <div className="receipts-container">
                    <NotificationContainer />
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header />
                    {/* <Breadcrumbs
                        items={[
                            {
                                title: "Home",
                                link: `/home?contract=${this.state.contractId}`
                            },
                            { title: "Recibos de Entradas" }
                        ]}
                    />
                    <ContractsPicker /> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Sidebar />
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <PageTitle
                                title='Recibos de entrada'
                                imgPath='./icons/sidebar/menu_recibos.svg'
                            />
                            <div
                                className="page-body"
                            >
                                {!this.state.loading ?
                                    (
                                        <>
                                            <Row>
                                                <Col xs={{ size: 12 }}>
                                                    <Nav tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                className={
                                                                    this.state.activeTab === "1"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() => {
                                                                    this.toggleTab("1");
                                                                }}
                                                            >
                                                                Recibos
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                    <TabContent activeTab={this.state.activeTab}>
                                                        <TabPane tabId="1">
                                                            <br />
                                                            {this.state.receipts.length > 0 ? (
                                                                <Table size="sm" striped borderless>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Nº RE</th>
                                                                            <th>DATA</th>
                                                                            <th>LOCAÇÃO</th>
                                                                            <th>PESO</th>
                                                                            <th>ARQUIVOS</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>{this.renderData()}</tbody>
                                                                </Table>
                                                            ) : (
                                                                <div className="no-items">
                                                                    <strong></strong>
                                                                </div>
                                                            )}
                                                        </TabPane>
                                                    </TabContent>
                                                </Col>
                                            </Row>
                                            <Modal centered isOpen={this.state.selectedReceipt !== null}>
                                                <ModalHeader>Arquivos</ModalHeader>
                                                <ModalBody className="options-info-modal">
                                                    {this.state.selectedReceipt !== null && this.state.selectedReceipt.arquivos && this.state.selectedReceipt.arquivos.length ? (
                                                        <Table size="sm" striped borderless>
                                                            <thead>
                                                                <tr>
                                                                    <th>ARQUIVOS</th>
                                                                    <th>DATA</th>
                                                                    <th>OPÇÕES</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>{this.renderFiles(this.state.selectedReceipt.arquivos)}</tbody>
                                                        </Table>
                                                    ) : (
                                                        <div className="no-items" style={{ textAlign: 'center' }}>
                                                            <strong>
                                                                Nenhum arquivo disponível
                                                            </strong>
                                                        </div>
                                                    )}
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button
                                                        className="btn-hollow"
                                                        onClick={() => {
                                                            this.toggleFileModal(null);
                                                        }}
                                                    >
                                                        FECHAR
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        </>
                                    )
                                    :
                                    (
                                        <Row>
                                            <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }} style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
                                                <Spinner></Spinner>
                                            </Col>
                                        </Row>
                                    )
                                }
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <a href="/commercialvisit">
                                            <img
                                                style={{
                                                    margin: "auto",
                                                    marginTop: "55px", width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                src={"./images/banners/agendamento.png"}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
