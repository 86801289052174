// SCRIPT DE LÓGICA DE AUTENTICAÇÃO DO LOGIN
export const isAuthenticated = () => { 
    let token = JSON.parse(localStorage.getItem('token'));
    if(!localStorage.getItem('_token')) {
        return false;
    }
    if(!token) {
        localStorage.removeItem('token');
        localStorage.removeItem('_token');
        return false;
    }
    if(!token.authenticated) {
        localStorage.removeItem('token');
        localStorage.removeItem('_token');
        return false;
    }
    if(!token.accessToken) {
        localStorage.removeItem('token');
        localStorage.removeItem('_token');
        return false;
    }
    if(new Date() > new Date(token.expiration)) {
        localStorage.removeItem('token');
        localStorage.removeItem('_token');
        return false;
    }
    return true;
 }