import React, { Component } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Form,
    FormGroup,
    Label
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import moment from "moment";
import "moment/locale/pt-br";
import { history } from "../../helper/history";

import "./styles.css";

import ReactGA from "react-ga";

export default class Map extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/mapa");
    }

    getSHBranchs = async () => {
        let response = await api.get("/api/filial/all");
    };

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );
        document.title = `SH - Mapa`;
        // this.getSHBranchs();
    };

    render() {
        return (
            <>
                <div className="map-container">
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header activeTab="map" />
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            {/* <iframe
                                id="gmap-canvas"
                                src="https://www.google.com/maps/d/embed?mid=1Fe7JKi1fxB0MA8QJJPAId3HeWkiD4CB2&hl=pt-BR"
                                width="100%"
                                height="100%"
                            ></iframe> */}
                            <iframe
                                id="gmap-canvas"
                                src="https://www.google.com/maps/d/u/0/embed?mid=1gadjQGDqZfswi4W9-4-atdi5O3eOP_0&ehbc=2E312F&noprof=1"
                                width="100%"
                                height="100%"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
