import React, { useState } from "react";
import MultiStepProgressBar from "../MultiStepProgressBar";

const ProgressExample = () => {
    const [page, setPage] = useState("0");

    const nextPage = (page) => {
        setPage(page);
    };

    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
            case "1":
                setPage("pageone");
                break;
            case "2":
                setPage("pagetwo");
                break;
            case "3":
                setPage("pagethree");
                break;
            case "4":
                setPage("pagefour");
                break;
            default:
                setPage("0");
        }
    };

    return (
        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
    );
};

export default ProgressExample;
