import React, { Component } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Form,
    FormGroup,
    Label
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import moment from "moment";
import "moment/locale/pt-br";
import { history } from "../../helper/history";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import "./styles.css";

import ReactGA from "react-ga";

export default class NewCatalog extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            catalogArray: [
                {
                    Title: 'Catálogo SH'
                },
                {
                    Title: 'Manual de Descarga Mecanizada'
                },
                {
                    Title: 'Cartilha Andaimes'
                },
                {
                    Title: 'Cartilha Perfis C (Aço e Alumínio)'
                },
                {
                    Title: 'Cartilha Fôrma para Paredes, Pilares e Vigas (Concreform)'
                },
                {
                    Title: 'Cartilha Fôrma para Laje'
                },
                {
                    Title: 'Cartilha Indenizações'
                },
                {
                    Title: 'Cartilha Vigas de Madeira'
                },
                {
                    Title: 'Cartilha Torres de Carga'
                },
                {
                    Title: 'Cartilha Escoras'
                },
                {
                    Title: 'Cartilha Fôrma para Paredes, Pilares e Vigas (Tekko)'
                },
            ],
            filteredItems: [
                {
                    Title: 'Catálogo SH'
                },
                {
                    Title: 'Manual de Descarga Mecanizada'
                },
                {
                    Title: 'Cartilha Andaimes'
                },
                {
                    Title: 'Cartilha Perfis C (Aço e Alumínio)'
                },
                {
                    Title: 'Cartilha Fôrma para Paredes, Pilares e Vigas (Concreform)'
                },
                {
                    Title: 'Cartilha Fôrma para Laje'
                },
                {
                    Title: 'Cartilha Indenizações'
                },
                {
                    Title: 'Cartilha Vigas de Madeira'
                },
                {
                    Title: 'Cartilha Torres de Carga'
                },
                {
                    Title: 'Cartilha Escoras'
                },
                {
                    Title: 'Cartilha Fôrma para Paredes, Pilares e Vigas (Tekko)'
                },
            ],
            filterText: '',
            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/catalogos");
    }

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );
        document.title = `SH - Documentos importantes`;
    };



    render() {
        return (
            <>
                <div className="catalog-container">
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    {/* <Header activeTab="catalog" /> */}
                    <Header />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Sidebar />
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <PageTitle
                                title='Documentos importantes'
                                imgPath='./icons/header/note-gray.svg'
                            />
                            <div
                                className="page-body"
                            >
                                <Row>
                                    <div className="col-md-12">
                                        <div className="search-input">
                                            {/* <img
                                                src={Search}
                                                alt='cover' /> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                <g clip-path="url(#clip0_995_3156)">
                                                    <path d="M14.7913 13.7832L10.2434 9.23531C11.9894 6.99028 11.8336 3.73586 9.77206 1.67435C7.5396 -0.558116 3.90681 -0.558116 1.67435 1.67435C-0.558116 3.90681 -0.558116 7.5396 1.67435 9.77206C2.79058 10.8883 4.25626 11.4464 5.7232 11.4464C6.96514 11.4464 8.20582 11.0442 9.23531 10.2434L13.7832 14.7913C13.9227 14.9309 14.105 15 14.2873 15C14.4695 15 14.6518 14.9309 14.7913 14.7913C15.0691 14.5135 15.0691 14.0623 14.7913 13.7832ZM2.68248 8.76393C1.00561 7.08707 1.00561 4.35934 2.68248 2.68248C3.52091 1.84405 4.62206 1.42546 5.7232 1.42546C6.82435 1.42546 7.9255 1.84405 8.76393 2.68248C10.4408 4.35934 10.4408 7.08707 8.76393 8.76393C7.08707 10.4408 4.35934 10.4408 2.68248 8.76393Z" fill="#7A7777" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_995_3156">
                                                        <rect width="15" height="15" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <Input
                                                placeholder=''
                                                value={this.state.filterText}
                                                onChange={(e) => {
                                                    this.setState({ filterText: e.target.value });

                                                    const textToFilter = e.target.value;

                                                    if (textToFilter) {
                                                        const filteredElements = this.state.catalogArray.filter(item =>
                                                            (item.Title && item.Title.toLowerCase().includes(textToFilter.toLowerCase()))
                                                        );
                                                        this.setState({ filteredItems: filteredElements });
                                                    }
                                                    else {
                                                        this.setState({ filteredItems: this.state.catalogArray });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <Col md={{ size: 12 }}>
                                        {(this.state.filteredItems) && (
                                            <>
                                                {(this.state.filteredItems.map((filteredItem) => (
                                                    // <Accordion
                                                    //     label={filteredItem.Title}
                                                    //     content={[
                                                    //         <>
                                                    //             {filteredItem.Content}
                                                    //         </>
                                                    //     ]}
                                                    // />
                                                    <>
                                                        {(filteredItem.Title == 'Catálogo SH') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/Pic-Catalogo_SH.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Catálogo SH
                                                                    </div>
                                                                    {/* <div className="catalog-card-description">
                                                                Aqui você encontrará todos os nossos catálogos SH, basta clicar na imagem para ver e baixar o catálogo.
                                                            </div> */}
                                                                    {/* <div className="catalog-card-bullet-points">
                                                                - Sobre a SH
                                                                - Soluções para Paredes, Pilares e Vigas em Concreto
                                                                - Soluções para Laje em Concreto
                                                                - Soluções para Acesso
                                                                - Soluções em Escoramentos
                                                            </div> */}
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://drive.google.com/file/d/1aTuLtFmui3AJ5RiE6uuefh6mBBOqFFPS/view?usp=sharing"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {(filteredItem.Title == 'Manual de Descarga Mecanizada') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/manualdecarga.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Manual de Descarga Mecanizada
                                                                    </div>
                                                                    {/* <div className="catalog-card-description">
                                                                    Aqui você encontrará todos os nossos catálogos SH, basta clicar na imagem para ver e baixar o catálogo.
                                                                </div> */}
                                                                    {/* <div className="catalog-card-bullet-points">
                                                                    - Introdução
                                                                    - Estudo de Caso
                                                                    - Famílias de Equipamentos
                                                                    - Como Montar Cargas?
                                                                    - Exemplos
                                                                </div> */}
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://drive.google.com/file/d/1-HK_R4YG_rcnYpERwyHRV1Ke2WKqqZKk/view?usp=share_link"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Andaimes') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-modex-port-1.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Andaimes
                                                                    </div>
                                                                    {/* <div className="catalog-card-description">
                                                                    Aqui você encontrará todos os nossos catálogos SH, basta clicar na imagem para ver e baixar o catálogo.
                                                                </div> */}
                                                                    {/* <div className="catalog-card-bullet-points">
                                                                    - Introdução
                                                                    - Classificação dos Equipamentos
                                                                    - Tipos de Cuidados
                                                                    - Como podemos Ajudar
                                                                    - Dicas Práticas
                                                                    - Como Economizar no Frete
                                                                    - Como Evitar Indenizações
                                                                </div> */}
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/Modex-port.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Perfis C (Aço e Alumínio)') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-perfilC-port.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Perfis C (Aço e Alumínio)
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/PerfilC-port.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Fôrma para Paredes, Pilares e Vigas (Concreform)') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-concreform-port.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Fôrma para Paredes, Pilares e Vigas (Concreform)
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/Concreform-port.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Fôrma para Laje') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-topec-port.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Fôrma para Laje
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/Topec-port.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Indenizações') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/cartilhamelhorespraticas.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Indenizações
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/cartilha+de+indenizacao+5.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Vigas de Madeira') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-viga-sh20-port-curvas.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Vigas de Madeira
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/Viga-sh20.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Torres de Carga') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-LTT-port.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Torres de Carga
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/LTT-port.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Escoras') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-escora-port-curvas.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Escoras
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/Escora-port.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(filteredItem.Title == 'Cartilha Fôrma para Paredes, Pilares e Vigas (Tekko)') && (
                                                            <div className="catalog-card">
                                                                <div className="catalog-card-image">
                                                                    <img
                                                                        src={
                                                                            "./images/thumbs/catalog/novo-material-tekko-port.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="catalog-card-texts">
                                                                    <div className="catalog-card-title">
                                                                        Cartilha Fôrma para Paredes, Pilares e Vigas (Tekko)
                                                                    </div>
                                                                    <div className="catalog-card-button">
                                                                        <a
                                                                            href="https://kroonar.s3.amazonaws.com/Tekko-port.pdf"
                                                                            target="_blank"
                                                                            className='card-button'
                                                                        >
                                                                            Visualizar
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>

                                                )))}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
