import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import { Table, Row, Col } from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import ContractsPicker from "../../components/contract_selector";
import Breadcrumbs from "../../components/breadcrumbs";
import "./styles.css";
import moment from "moment";

import ReactGA from "react-ga";

class Danfes extends Component {
    constructor() {
        super();
        this.state = {
            contractId: util.queryParams().contract,
            danfes: [],

            redirect: "",
            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/Danfes");
    }

    getContractDanfes = async id => {
        const promises = [api.get(`/api/contrato/${id}/danfes`)];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];
                switch (x) {
                    case 0:
                        this.setState({ danfes: resp.data });
                        console.log(resp.data);
                        break;
                }
            }
        });
    };

    renderData = () => {
        return this.state.danfes.map((value, i) => {
            return (
                <tr key={i}>
                    <td>{value.numero}</td>
                    <td>{moment(value.data).format("L")}</td>
                    <td>
                        <svg
                            onClick={() => {
                                util.downloadFile(
                                    `/api/contrato/${this.state.contractId}/danfe/${value.arquivoSemExtensao}`,
                                    value.arquivoSemExtensao
                                );
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </td>
                </tr>
            );
        });
    };

    componentDidMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("token")).accessToken
            }`
        };
        this.getContractDanfes(this.state.contractId);
        // document.title = `${document.title} - DANFES`
    };

    render() {
        return (
            <>
                <div className="danfes-container">
                    <NotificationContainer />
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header />
                    <Breadcrumbs
                        items={[
                            {
                                title: "Home",
                                link: `/home?contract=${this.state.contractId}`
                            },
                            { title: "Nota Fiscal (DANFE)" }
                        ]}
                    />
                    <ContractsPicker />
                    <Row>
                        <Col xs={{ size: 12 }}>
                            {this.state.danfes.length > 0 ? (
                                <Table size="sm" striped borderless>
                                    <thead>
                                        <tr>
                                            <th>Nº NOTA</th>
                                            <th>DATA</th>
                                            <th>BAIXAR</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderData()}</tbody>
                                </Table>
                            ) : (
                                <div className="no-items">
                                    <strong>Itens não disponíveis</strong>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Danfes;
