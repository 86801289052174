import React, { Component } from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import ContractPicker from "../../components/contract_selector";
import BodyHome from "../../components/body_home";

import ReactGA from "react-ga";

class Home extends Component {
    constructor() {
        super();
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/Home_Page");
    }

    render() {
        return (
            <>
                <Header />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <Sidebar />
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        <ContractPicker details={true} />
                        <BodyHome />
                    </div>
                </div>
            </>
        );
    }
}

export default Home;
