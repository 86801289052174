import React, { useState, useEffect } from "react";
import "./styles.css";
// import "./styles.scss";
import api from "../../Services/api.js";

const MobileSideBar = props => {
    // const [tooltipProfile, setTooltipProfile] = useState(false);
    // const [tooltipMap, setTooltipMap] = useState(false);
    // const [tooltipMedia, setTooltipMedia] = useState(false);
    // const [tooltipCatalog, setTooltipCatalog] = useState(false);
    // const [tooltipShowroom, setTooltipShowroom] = useState(false);
    // const [tooltipLoadUnloadReport, setTooltipLoadUnloadReport] = useState(false);
    // const [userId] = useState(JSON.parse(localStorage.getItem("token")).userId);
    // const [userInfo, setUserInfo] = useState({});
    const [activeMenu, setActiveMenu] = useState('');
    const [userId] = useState(JSON.parse(localStorage.getItem("token")).userId);
    const [userInfo, setUserInfo] = useState({});
    const [adminUserId, setAdminUserId] = useState({});

    useEffect(() => {
        const getUserInfos = () => {
            api.get(`/api/usuario/${userId}`)
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        };

        getUserInfos();
    }, [userId])

    if (!activeMenu) {
        const location = window.location.pathname;

        if (location.includes('home')) {
            setActiveMenu('home');
        }
        else if (location.includes('balance')) {
            setActiveMenu('balance');
        }
        else if (location.includes('moves')) {
            setActiveMenu('moves');
        }
        else if (location.includes('debits')) {
            setActiveMenu('debits');
        }
        else if (location.includes('danfes')) {
            setActiveMenu('danfes');
        }
        else if (location.includes('notas-fiscais')) {
            setActiveMenu('notasfiscais');
        }
        else if (location.includes('receipts')) {
            setActiveMenu('receipts');
        }
        else if (location.includes('invoices')) {
            setActiveMenu('invoices');
        }
        else if (location.includes('projects')) {
            setActiveMenu('projects');
        }
        else if (location.includes('visits')) {
            setActiveMenu('visits');
        }
        else if (location.includes('commercialvisit')) {
            setActiveMenu('commercialvisit');
        }
        else if (location.includes('drives')) {
            setActiveMenu('drives');
        }
        else if (location.includes('drives-moves')) {
            setActiveMenu('drives-moves');
        }
        // else if (location.includes('balance')) {
        //     setActiveMenu('balance');
        // }
        // else if (location.includes('balance')) {
        //     setActiveMenu('balance');
        // }
    }

    const getIfUserIsAdmin = async () => {
        const userId = await localStorage.getItem("email");
        // alert(`Este é o userId ${userId}`);
        setAdminUserId(userId);
    };

    if (!adminUserId) {
        getIfUserIsAdmin();
    }

    return (
        <>
            <div className={`mobile-menu-container ${props.isOpen ? '' : 'hidden'}`}>
                {/* <img src={"./images/menu-lateral.svg"} /> */}
                <div
                    className="menu-content"
                >
                    <div className={`menu-item user-name`}>
                        <a href="/user">
                            <img src={"./icons/sidebar/user.svg"} />
                            {(userInfo.nome) && (
                                <>
                                    {userInfo.nome}
                                </>
                            )}
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'home') ? 'active' : ''}`}>
                        <a href="/home">
                            <img src={"./icons/sidebar/menu_home.svg"} />
                            Início
                        </a>

                    </div>
                    <div className={`menu-item ${(activeMenu === 'balance') ? 'active' : ''}`}>
                        <a href="/balance">
                            <img src={"./icons/sidebar/menu_saldo.svg"} />
                            Saldo
                        </a>

                    </div>
                    <div className={`menu-item ${(activeMenu === 'moves') ? 'active' : ''}`}>
                        <a href="/moves">
                            <img src={"./icons/sidebar/menu_movimentacao.svg"} />
                            Movimentações
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'notasfiscais') ? 'active' : ''}`}>
                        <a href="/notas-fiscais">
                            <img src={"./icons/sidebar/menu_notas.svg"} />
                            Notas fiscais
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'receipts') ? 'active' : ''}`}>
                        <a href="/receipts">
                            <img src={"./icons/sidebar/menu_recibos.svg"} />
                            Recibos de entrada
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'debits') ? 'active' : ''}`}>
                        <a href="/debits">
                            <img src={"./icons/sidebar/menu_notas.svg"} />
                            Notas de débito
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'invoices') ? 'active' : ''}`}>
                        <a href="/invoices">
                            <img src={"./icons/sidebar/menu_faturas.svg"} />
                            Faturas
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'projects') ? 'active' : ''}`}>
                        <a href="/projects">
                            <img src={"./icons/sidebar/menu_projetos.svg"} />
                            Projetos
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'visits') ? 'active' : ''}`}>
                        <a href="/visits">
                            <img src={"./icons/sidebar/menu_relatorio_visitas.svg"} />
                            Relatórios de visita
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'commercialvisit') ? 'active' : ''}`}>
                        <a href="/commercialvisit">
                            <img src={"./icons/sidebar/menu_nova_visita.svg"} />
                            Nova visita
                        </a>
                    </div>
                    <div className={`menu-item ${(activeMenu === 'drives') ? 'active' : ''}`}>
                        <a href="/drives">
                            <img src={"./icons/sidebar/menu_novo_agendamento.svg"} />
                            Novo agendamento
                        </a>
                    </div>
                    {(adminUserId === 'alinne.nascimento@sh.com.br') && (
                        <div className={`menu-item ${(activeMenu === 'admin') ? 'active' : ''}`}>
                            <a href="/admin">
                                <img src={"./icons/sidebar/menu_projetos.svg"} />
                                Admin
                            </a>
                        </div>
                    )}
                    <div className={`menu-item bottom`}>
                        <div className="links">
                            <a href="/map">
                                <img src={"./icons/sidebar/mobile/pin.svg"} />
                            </a>
                            <a href="/catalog">
                                <img src={"./icons/sidebar/mobile/sheet.svg"} />
                            </a>
                            <a href='https://sh.com.br/showroom/'
                                target='_blank'>
                                <img src={"./icons/sidebar/mobile/lamp.svg"} />
                            </a>
                            <a href="/media">
                                <img src={"./icons/sidebar/mobile/smile.svg"} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileSideBar;
