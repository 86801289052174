import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isAuthenticated } from '../../auth';
import "./styles.css";

class BodyNotFound extends Component {
    render() {
        return (
            <div>
                {/* <center> */}
                <div>
                    <center>
                        <div id="box-not-found-desktop">
                            <div>
                                <img
                                    id="logo-not-found-desktop"
                                    src={"./images/wrecking.png"}
                                    alt="logo-login"
                                />
                                <div style={{marginBottom: '10%'}}></div>
                                <h2 id="text-not-found-desktop">
                                    PÁGINA NÃO ENCONTRADA
                                </h2>
                                <h4 id="text-not-found-desktop">
                                    Algo deu errado ou a página não existe
                                </h4>
                                <form>
                                   { isAuthenticated() ? <Link to="/home">
                                        <button
                                            type="submit"
                                            id="button-not-found-desktop"
                                            href="/home"
                                        >
                                            VOLTAR PARA A HOME
                                        </button>
                                    </Link> : 
                                    <Link to="/login">
                                    <button
                                        type="submit"
                                        id="button-not-found-desktop"
                                        href="/home"
                                    >
                                        VOLTAR PARA O LOGIN
                                    </button>
                                </Link> }
                                </form>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        );
    }
}

export default BodyNotFound;
