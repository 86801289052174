// import React, { Component, useRef } from "react";
import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import {
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
// import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import PageTitle from "../../components/pageTitle";
import moment from "moment";
import "moment/locale/pt-br";
import jsPDF from 'jspdf';
// import { DownloadTableExcel } from 'react-export-table-to-excel';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DataTable from 'react-data-table-component';
import "./styles.css";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import ReactGA from "react-ga";

export default class Balance extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            userId: JSON.parse(localStorage.getItem('token')).userId,
            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
            balance: [],
            files: [],
            loading: false,
            selectedBalance: null,
            data: moment().format("YYYY-MM-DD"),
            dataYesterday: moment().day() !== 1 ? moment().subtract(1, 'days').format("YYYY-MM-DD") : moment().subtract(3, 'days').format("YYYY-MM-DD"),
            dataYesterdayToShow: moment().day() !== 1 ? moment().subtract(1, 'days').format("DD/MM/YYYY") : moment().subtract(3, 'days').format("DD/MM/YYYY"),
            mustShowDataYesterdayToShow: false,
            total: 0,
            pesoTotal: 0,
            mustShowValorTotal: true,
        };
        // this.myRef = React.createRef();
        this.tableRef = React.createRef(null);
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/saldo");
    }

    toggleFileModal = balance => {
        this.setState({
            selectedBalance: balance
        });
    };

    getBalance = async (date) => {
        const { contractId, data, dataYesterday, dataYesterdayToShow } = this.state;

        this.setState({ total: 0, pesoTotal: 0, mustShowDataYesterdayToShow: false });
        console.log(`data ${data}`);
        // console.log(`ontem ${dataYesterday}`);
        // console.log(`ontem display ${dataYesterdayToShow}`);
        // console.log(`hoje ${moment().format("YYYY-MM-DD")}`);


        let dateToUse = date ? date : data;

        console.log(`ontem ${moment().day()}`);

        // if (dateToUse === moment().format("YYYY-MM-DD") || dateToUse > moment().format("YYYY-MM-DD")) {
        //     dateToUse = dataYesterday;
        //     this.setState({ mustShowDataYesterdayToShow: true });
        //     console.log(`data usada ${dataYesterdayToShow}`);
        // }

        api.get(`/api/usuario/${this.state.userId}/Contratos`).then((res) => {
            console.log(`/api/contrato/${contractId}/saldo/${dateToUse}`);
            api.get(`/api/contrato/${contractId}/saldo/${dateToUse}`).then(response => {
                let balance = response.data;
                console.log('saldo datasource');
                console.log(response.data);
                // let total = balance.reduce(function (acc, obj) { return acc + (obj.faturamentoFechado ? obj.valor : 0); }, 0);
                // let pesoTotal = balance.reduce(function (bcc, obj) { return bcc + (obj.pesoTotalEq ? obj.pesoTotalEq : 0); }, 0);

                let maxValue = 0;
                let maxKg = 0;

                for (let item of balance) {
                    console.log(`item ${item}`);
                    console.log(item);
                    console.log(`mustShowValorTotal ${this.state.mustShowValorTotal}`);
                    console.log(`item.valor ${item.valor}`);
                    // if (item.faturamentoFechado) {
                    //     maxValue += item.valor;
                    // }
                    maxValue += item.valor;
                    maxKg += item.pesoTotalEq;
                }

                // if (!total) {
                //     this.setState({
                //         mustShowValorTotal: false
                //     });
                // }
                console.log(`maxValue ${maxValue}`);
                // if (!maxValue) {
                //     this.setState({
                //         mustShowValorTotal: false
                //     });
                // }

                this.setState({
                    balance,
                    total: maxValue,
                    pesoTotal: maxKg,
                });
            })
                .catch(err => {
                    console.log(err);
                });
        })
    }

    exportTableToExcel = () => {
        // Select the table element
        const tableId = 'balance-table';
        const filename = 'Saldo.xlsx';
        const table = document.getElementById(tableId);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert the HTML table to a worksheet
        const worksheet = XLSX.utils.table_to_sheet(table);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Write the workbook and save it as a file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, filename);
    }

    getContractInvoices = async id => {
        const promises = [
            api.get(`/api/contrato/${id}/faturas`),
            api.get(`/api/Contrato/${id}/Lista/Faturas`) // 123 = ervId
        ];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];
                switch (x) {
                    case 0:
                        this.setState({ contract_invoices: resp.data });
                        break;
                    case 1:
                        this.setState({ files: resp.data });
                        break;
                }
            }
        });
    };

    getInvoicesArray = () => {
        const invoicesArray = [];
        this.state.balance.map((value, key) => {
            // console.log(value);
            invoicesArray.push({
                // id: value.idEquipamento,
                id: key,
                equipamento: value.equipamento,
                qtdeObra: value.qtdeObra,
                pesoTotalEq: (value.pesoTotalEq ? util.currenzializeWithoutDecimals(value.pesoTotalEq) : ""),
                // faturamentoFechado: this.state.mustShowValorTotal ? (value.faturamentoFechado ? util.currenzialize(value.valor) : "") : ""
                faturamentoFechado: this.state.mustShowValorTotal ? util.currenzialize(value.valor) : "",
                valor: this.state.mustShowValorTotal ? util.currenzialize(value.valor) : ""
            });
        });

        return invoicesArray;
    };

    columns = [
        {
            name: 'EQUIPAMENTO',
            selector: row => row.equipamento,
            sortable: true,
            reorder: true,
        },
        {
            name: 'QTD',
            selector: row => row.qtdeObra,
            sortable: true,
            reorder: true,
        },
        {
            name: 'PESO',
            selector: row => row.pesoTotalEq,
            sortable: true,
            reorder: true,
        },
        {
            name: 'VALOR',
            // selector: row => row.faturamentoFechado,
            selector: row => row.valor,
            sortable: true,
            reorder: true,
        },
    ];

    renderInvoices = () => {
        return this.state.balance.map((value, key) => {
            // console.log(value);
            return (
                <tr key={key}>
                    {/* <td>{value.idEquipamento}</td> */}
                    <td>{value.equipamento}</td>
                    <td>{value.qtdeObra}</td>
                    <td>{value.pesoTotalEq ? util.currenzializeWithoutDecimals(value.pesoTotalEq) : ""}</td>
                    {/* {this.state.mustShowValorTotal && (
                        <td>{value.faturamentoFechado ? util.currenzialize(value.valor) : ""}</td>
                    )} */}
                    {this.state.mustShowValorTotal && (
                        <td>{value.valor}</td>
                    )}
                    {/* <td>
                        <svg
                            style={{ 'cursor': 'pointer' }}
                            onClick={() => {
                                const promises = [
                                    api.get(`/api/contrato/${this.state.contractId}/Download/Saldo/${moment().format('YYYY-MM-DD')}`),
                                ];
                                Promise.all(promises).then(response => {
                                    for (let x = 0; x < response.length; x++) {
                                        let resp = response[x];

                                        util.downloadFile(
                                            `${resp.data}`, value.arquivoSemExtensao
                                        );
                                    }
                                });
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </td> */}
                </tr>
            );
        });
    };

    renderFiles = (files) => {
        return files.map((value, i) => {
            return (
                <tr key={i}>
                    <td>{value.arquivoSemExtensao}</td>
                    <td>{moment(value.data).format("L")}</td>
                    <td>
                        <svg
                            style={{ 'cursor': 'pointer' }}
                            onClick={() => {
                                util.downloadFile(
                                    `/api/contrato/${this.state.contractId}/saldo/${value.arquivoSemExtensao}`,
                                    value.arquivoSemExtensao
                                );
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </td>
                </tr>
            );
        });
    };

    componentDidMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        this.getBalance();
    };

    // doc = new jsPDF();
    // doc = new jsPDF({
    //     orientation: 'landscape',
    //     unit: 'in',
    //     format: [4, 2],
    // });

    // exportToPDF = (reportTemplateRef) => {
    //     const doc = new jsPDF({
    //         orientation: 'landscape',
    //         unit: 'in',
    //         format: [4, 2],
    //     });

    //     doc.html(reportTemplateRef.current, {
    //         async callback(doc) {
    //             await doc.save('Saldo');
    //         },
    //     });
    // }
    // tableRef = React.createRef(null);

    render() {

        // const reportTemplateRef = useRef(null);

        return (
            <>
                <Header />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <Sidebar />
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        <NotificationContainer />
                        {this.state.redirect !== "" ? (
                            <Redirect to={`${this.state.redirect}`} />
                        ) : null}
                        <PageTitle
                            title='Saldo geral'
                            imgPath='./icons/saldo.svg'
                        />
                        {/* <ContractsPicker /> */}
                        <div
                            className="page-body"
                        >
                            <Row>
                                <Col
                                    md={{ size: 12 }}
                                    style={{
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <FormGroup style={{ marginBottom: 5 }}>
                                        {/* <Label>Data</Label> */}
                                        <Input
                                            type="date"
                                            name="data"
                                            value={this.state.data}
                                            placeholder="__/__/____"
                                            onChange={e => {
                                                if (
                                                    moment(
                                                        e.target.value,
                                                        "YYYY-MM-DD"
                                                    ).format("YYYY-MM-DD") ===
                                                    e.target.value
                                                ) {
                                                    this.setState({ data: moment(e.target.value).format("YYYY-MM-DD") });
                                                    // console.log('o que deveria ser');
                                                    // console.log(moment(e.target.value).format("YYYY-MM-DD"));
                                                    // console.log('o que é');
                                                    // console.log(this.state.data);
                                                    this.getBalance(moment(e.target.value).format("YYYY-MM-DD"));
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {(this.state.mustShowDataYesterdayToShow) && (
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <FormGroup
                                            style={{
                                                marginBottom: '10px',
                                                marginTop: '10px'
                                            }}>
                                            <Label
                                                style={{
                                                    // border: '1px solid #7c7c7c',
                                                    border: '4px solid #f64545',
                                                    padding: '10px',
                                                    fontSize: '10px',
                                                    borderRadius: '6px',
                                                    textDecoration: 'underline'
                                                }}
                                            >
                                                {`IMPORTANTE: As informações contidas abaixo ou nos documentos para download referem-se ao último dia útil: ${this.state.dataYesterdayToShow}`}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={{ size: 12 }}>
                                    {
                                        this.state.balance.length > 0 ? (
                                            <>
                                                <span className="d-block text-right mb-4">
                                                    <div>
                                                        PESO TOTAL<br />
                                                        <div className="font-weight-normal"
                                                            style={{
                                                                width: '100%',
                                                                maxWidth: '150px',
                                                                whiteSpace: 'nowrap',
                                                                height: '30px',
                                                                borderRadius: '4px',
                                                                background: '#F7CD45',
                                                                color: "#FFF",
                                                                fontFamily: 'Roboto',
                                                                fontSize: '20px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                paddingTop: '4px',
                                                                paddingLeft: '9px',
                                                            }}
                                                        >
                                                            {util.currenzializeWithoutDecimals(this.state.pesoTotal)} kg
                                                        </div>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </div>

                                                    {this.state.mustShowValorTotal && (
                                                        <>
                                                            <div>
                                                                VALOR TOTAL <br />
                                                                <div className="font-weight-normal"
                                                                    style={{
                                                                        width: '100%',
                                                                        maxWidth: '150px',
                                                                        whiteSpace: 'nowrap',
                                                                        height: '30px',
                                                                        borderRadius: '4px',
                                                                        background: '#F7CD45',
                                                                        color: "#FFF",
                                                                        fontFamily: 'Roboto',
                                                                        fontSize: '20px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: 'normal',
                                                                        paddingTop: '4px',
                                                                        paddingLeft: '9px',
                                                                    }}
                                                                >
                                                                    R$ {util.currenzialize(this.state.total)}
                                                                </div>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                        </>
                                                    )}

                                                    {/* <Button
                                                        onClick={this.getBalance}
                                                        className="btn-hollow">
                                                        FILTRAR
                                                    </Button> */}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn-hollow"
                                                        onClick={() => {
                                                            const { contractId, data, dataYesterday } = this.state;

                                                            let dateToUse = data;

                                                            // if (data === moment().format("YYYY-MM-DD") || data > moment().format("YYYY-MM-DD")) {
                                                            //     dateToUse = dataYesterday;
                                                            //     this.setState({ mustShowDataYesterdayToShow: true });
                                                            // }

                                                            const promises = [
                                                                // api.get(`/api/contrato/${this.state.contractId}/Download/Saldo/${moment().format('YYYY-MM-DD')}`),
                                                                api.get(`/api/contrato/${contractId}/Download/Saldo/${dateToUse}`),
                                                            ];
                                                            Promise.all(promises).then(response => {
                                                                for (let x = 0; x < response.length; x++) {
                                                                    let resp = response[x];

                                                                    // util.downloadFile(
                                                                    //     `${resp.data}`, `Saldo-${moment().format('YYYY-MM-DD')}`
                                                                    // );
                                                                    util.downloadFile(
                                                                        `${resp.data}`, `Saldo-${this.state.data}`
                                                                    );
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                "./icons/download-icon.svg"
                                                            }
                                                        />
                                                        EXPORTAR PDF
                                                    </button>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn-hollow"
                                                        onClick={() => {
                                                            this.exportTableToExcel();
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                "./icons/download-icon.svg"
                                                            }
                                                        />
                                                        EXPORTAR EXCEL
                                                    </button>
                                                    {/* <button
                                                        type="button"
                                                        className="btn-hollow"
                                                        onClick={() => {
                                                            const promises = [
                                                                api.get(`/api/contrato/${this.state.contractId}/Download/Saldo/${moment().format('YYYY-MM-DD')}`),
                                                            ];
                                                            Promise.all(promises).then(response => {
                                                                for (let x = 0; x < response.length; x++) {
                                                                    let resp = response[x];

                                                                    util.downloadFile(
                                                                        `${resp.data}`, `Saldo-${moment().format('YYYY-MM-DD')}`
                                                                    );
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                "./icons/download-icon.svg"
                                                            }
                                                        />
                                                        EXPORTAR EXCEL
                                                    </button> */}
                                                    {/* <DownloadTableExcel
                                                        filename="saldo"
                                                        sheet="saldo"
                                                        currentTableRef={this.tableRef.current}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn-hollow"
                                                        >
                                                            <img
                                                                src={
                                                                    "./icons/download-icon.svg"
                                                                }
                                                            />
                                                            EXPORTAR EXCEL
                                                        </button>
                                                    </DownloadTableExcel> */}
                                                    {/* <ReactHTMLTableToExcel
                                                        id="test-table-xls-button"
                                                        className="download-table-xls-button export-button btn-hollow"
                                                        table="balance-table"
                                                        filename="saldo"
                                                        sheet="saldo"
                                                        buttonText={[
                                                            <>
                                                                <img
                                                                    src={
                                                                        "./icons/download-icon.svg"
                                                                    }
                                                                />
                                                                EXPORTAR EXCEL
                                                            </>
                                                        ]}

                                                    /> */}

                                                    <FormGroup
                                                        style={{
                                                            marginBottom: 5,
                                                            marginLeft: 10,
                                                        }}
                                                    >

                                                    </FormGroup>
                                                    {/* <ReactHTMLTableToExcel
                                                        id="test-table-xls-button"
                                                        className="download-table-xls-button btn-hollow"
                                                        table="balance-table"
                                                        filename="saldo"
                                                        sheet="saldo"
                                                        buttonText="EXPORTAR EXCEL"
                                                    >
                                                        <img
                                                            src={
                                                                "./icons/download-icon.svg"
                                                            }
                                                        />
                                                    </ReactHTMLTableToExcel> */}
                                                </span>
                                                <div class="table-responsive"
                                                // ref={reportTemplateRef}
                                                >
                                                    <div className="desktop">
                                                        <DataTable
                                                            columns={this.columns}
                                                            data={this.getInvoicesArray()}
                                                            pagination
                                                            fixedHeader={true}
                                                            fixedHeaderScrollHeight={'600px'}
                                                        />
                                                    </div>
                                                    <div className="mobile">
                                                        <div class="table-responsive"
                                                        >
                                                            <Table
                                                                size="sm"
                                                                striped borderless
                                                                id="balance-table"
                                                            // ref={this.tableRef}

                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th>EQUIPAMENTO</th>
                                                                        <th>QTD</th>
                                                                        <th>PESO</th>
                                                                        {this.state.mustShowValorTotal && (
                                                                            <th>VALOR</th>
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.renderInvoices()}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="no-items">
                                                <strong>
                                                    Itens não disponíveis
                                                </strong>
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 12 }}>
                                    <a href="/catalog">
                                        <img
                                            style={{
                                                margin: "auto",
                                                marginTop: "55px", width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            src={"./images/banners/catalogo.png"}
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
