import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import {
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardTitle,
    CardText
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
// import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import moment from "moment";
import "moment/locale/pt-br";

import "./styles.css";

import ReactGA from "react-ga";

export default class Visits extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).idPropostaContrato,
            visits: [],
            files: [],

            selectedVisit: [],
            infoModal: false,
            loading: false,

            activeTab: "1"
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/relatorioVisita");
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    };

    toggleInfos = visit => {
        this.setState({
            selectedVisit: visit,
            infoModal: !this.state.infoModal
        });
    };

    getContractVisits = async id => {
        const promises = [
            api.get(`/api/contrato/${id}/visitastecnicas`), // 123 = ervId
            api.get(`/api/Contrato/${id}/Lista/Visitas`)
        ];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];
                switch (x) {
                    case 0:
                        this.setState({ visits: resp.data });
                        break;
                    case 1:
                        this.setState({ files: resp.data });
                        break;
                }
            }
        });
    };

    renderData = () => {
        return this.state.visits.map((value, key) => {
            return (
                <tr key={value.idERV}>
                    <td>{moment(value.dtVisita).format("L")}</td>
                    <td>{value.nmContato}</td>
                    <td>
                        {/* <svg
                            onClick={() => {
                                this.toggleInfos(value);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                        </svg> */}
                        <span title="Informações">
                            <img
                                style={{
                                    marginRight: "12px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    this.toggleInfos(value);
                                }}
                                src={"./icons/icone_opcoes.svg"}
                            />
                        </span>
                        {this.state.files[key] ? (
                            <span title="Arquivos">
                                <img
                                    style={{
                                        marginRight: "12px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        util.downloadFile(
                                            `/api/contrato/${this.state.contractId}/visita/${this.state.files[key].arquivoSemExtensao}`,
                                            this.state.files[key].arquivoSemExtensao
                                        );
                                    }}
                                    src={"./icons/icone_download.svg"}
                                />
                            </span>
                        ) : null}
                    </td>
                </tr>
            );
        });
    };

    renderFiles = () => {
        return this.state.files.map((value, i) => {
            return (
                <tr key={i}>
                    <td>{value.arquivoSemExtensao}</td>
                    <td>{moment(value.data).format("L")}</td>
                    <td>
                        <svg
                            onClick={() => {
                                util.downloadFile(
                                    `/api/contrato/${this.state.contractId}/visita/${value.arquivoSemExtensao}`,
                                    value.arquivoSemExtensao
                                );
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </td>
                </tr>
            );
        });
    };

    componentDidMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        this.getContractVisits(this.state.contractId);
        // document.title = `${document.title} - Notas de Débito`
    };

    render() {
        return (
            <>
                <div className="visits-container">
                    <NotificationContainer />
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header />
                    {/* <Breadcrumbs
                        items={[
                            {
                                title: "Home",
                                link: `/home?contract=${this.state.contractId}`
                            },
                            { title: "Relatório de Visitas" }
                        ]}
                    />
                    <ContractsPicker /> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Sidebar />
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <PageTitle
                                title='Relatório de Visitas'
                                imgPath='./icons/sidebar/menu_relatorio_visitas.svg'
                            />
                            <div
                                className="page-body"
                            >
                                <Row>
                                    <Col xs={{ size: 12 }}>
                                        {this.state.visits.length > 0 ? (
                                            <Table size="sm" striped borderless>
                                                <thead>
                                                    <tr>
                                                        <th>DATA DA VISITA</th>
                                                        <th>CONTATO DO CLIENTE</th>
                                                        <th>OPÇÕES</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='body-visits'>{this.renderData()}</tbody>
                                            </Table>
                                        ) : (
                                            <div className="no-items">
                                                <strong>
                                                    Itens não disponíveis
                                                </strong>
                                            </div>
                                        )}
                                        {/* <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={
                                            this.state.activeTab === "1"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            this.toggleTab("1");
                                        }}
                                    >
                                        Visítas
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            this.state.activeTab === "2"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            this.toggleTab("2");
                                        }}
                                    >
                                        Arquivos
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <br />
                                    {this.state.visits.length > 0 ? (
                                        <Table size="sm" striped borderless>
                                            <thead>
                                                <tr>
                                                    <th>DATA DA VISITA</th>
                                                    <th>CONTATO DO CLIENTE</th>
                                                    <th>OPÇÕES</th>
                                                </tr>
                                            </thead>
                                            <tbody className='body-visits'>{this.renderData()}</tbody>
                                        </Table>
                                    ) : (
                                        <div className="no-items">
                                            <strong>
                                                Itens não disponíveis
                                            </strong>
                                        </div>
                                    )}
                                </TabPane>
                                <TabPane tabId="2">
                                    <br />
                                    {this.state.files.length > 0 ? (
                                        <Table size="sm" striped borderless>
                                            <thead>
                                                <tr>
                                                    <th>ARQUIVOS</th>
                                                    <th>DATA</th>
                                                    <th>OPÇÕES</th>
                                                </tr>
                                            </thead>
                                            <tbody>{this.renderFiles()}</tbody>
                                        </Table>
                                    ) : (
                                        <div className="no-items">
                                            <strong>
                                                Itens não disponíveis
                                            </strong>
                                        </div>
                                    )}
                                </TabPane>
                            </TabContent> */}
                                    </Col>
                                </Row>
                                <Modal centered isOpen={this.state.infoModal}>
                                    <ModalHeader>INFORMAÇÕES DA VISITA</ModalHeader>
                                    <ModalBody className="options-info-modal">
                                        <div className="info-box">
                                            <ul>
                                                <li>
                                                    <span className="label">
                                                        NÚMERO DA VISITA
                                                    </span>
                                                    <br />
                                                    <span className="value">
                                                        {this.state.selectedVisit.nrERV ||
                                                            "-"}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="label">
                                                        CONTATO DO CLIENTE
                                                    </span>
                                                    <br />
                                                    <span className="value">
                                                        {this.state.selectedVisit
                                                            .nmContato || "-"}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="label">
                                                        ASSISTENTE TÉCNICO
                                                    </span>
                                                    <br />
                                                    <span className="value">
                                                        {this.state.selectedVisit
                                                            .nmFuncionario || "-"}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="label">
                                                        DATA DA VISITA
                                                    </span>
                                                    <br />
                                                    <span className="value">
                                                        {moment(
                                                            this.state.selectedVisit
                                                                .dtVisita
                                                        ).format("L") || "-"}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            className="btn-hollow"
                                            onClick={() => {
                                                this.toggleInfos([]);
                                            }}
                                        >
                                            CONCLUIR
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <a href="/commercialvisit">
                                            <img
                                                style={{
                                                    margin: "auto",
                                                    marginTop: "55px", width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                src={"./images/banners/agendamento.png"}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
