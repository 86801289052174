import React, { Component } from "react";
import {
    Row,
    Col,
    ButtonGroup,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner
} from "reactstrap";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import util from "../../util";
import _api from "../../Services/_api";
import { button as buttonTheme } from "../../themes";
import "./styles.css";
import Compress from "compress.js";
import Axios from "axios";

class BodyPictures extends Component {
    // <img src="https://imagens.canaltech.com.br/236607.471195-Lua.jpg"/>

    constructor() {
        super();
        this.state = {
            photos: [],
            photoToVisualize: "",
            photoToExclude: [],
            mode: 0, // 0 - VISUALIZAÇÃO || 1 - EXCLUSÃO

            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).idPropostaContrato,
            deletePhotoModalLoading: false,
            deletePhotoModal: false,
            deletePhotoID: "",
            uploading: false            
        };
    }

    toggleMode = () => {
        let { mode } = this.state;
        if (mode == 0) this.setState({ mode: 1 });
        else if (mode == 1) this.setState({ mode: 0 });
    };
    closeDeletePhotoModal = () => {
        let { deletePhotoModal } = this.state;
        deletePhotoModal = !deletePhotoModal;
        this.setState({ deletePhotoModal, deletePhotoID: "" });
    };

    toggleClass = img => {
        if (this.state.mode == 1) {
            this.setState({ deletePhotoModal: true, deletePhotoID: img._id });
            //this.deleteFile(img.id);
        } else if (this.state.mode == 0) {
            this.setState({ photoToVisualize: img });
        }
    };

    uploadFile = files => {                
        const compress = new Compress();
        compress.compress(files, {
            size: 0.1, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: false, // defaults to true, set false if you do not want to resize the image width and height
        }).then((compressedFiles) => {
            var promises = compressedFiles.map(cf => {
                const base64str = cf.data
                const imgExt = cf.ext
                const file = Compress.convertBase64ToFile(base64str, imgExt)
                
                // const byteArr = util.base64ToArrayBuffer(base64str);
                // let data = new FormData();
                // data.append("file", byteArr);

                let data = new FormData();
                data.append("file", file);
                data.append("fileGroup", "Fotos_Obra");
                data.append("contractId", this.state.contractId.toString());
                return _api.post(`/file`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })                   
            });

            Axios.all(promises)
                .then(Axios
                .spread((...responses) => {                    
                    NotificationManager.success(
                        "O upload da sua foto foi realizado com sucesso!",
                        "Sucesso!",
                        5000
                    );
                    this.setupPictures();                    
                }))
                .catch(error => {
                    NotificationManager.error(
                        "Ocorreu um erro ao enviar a foto, tente novamente mais tarde",
                        "Ocorreu um erro",
                        5000
                    );
                })
                .finally(() => {
                    this.setState({ uploading: false });
                })
        })
    };

    deleteFile = id => {
        this.setState({ deletePhotoModalLoading: true });
        _api.delete(`/file/${id}`)
            .then(response => {
                NotificationManager.success(
                    "A foto foi deletada com sucesso!",
                    "Sucesso!",
                    5000
                );
                this.setupPictures();
            })
            .catch(error => {
                NotificationManager.error(
                    "Ocorreu um erro ao apagar a foto, tente novamente mais tarde",
                    "Ocorreu um erro",
                    5000
                );
            })
            .finally(response => {
                this.setState({ deletePhotoModalLoading: false });
                this.closeDeletePhotoModal();
            });
    };

    setupPictures = async () => {
        await _api
            .get(`/file/${this.state.contractId}`)
            .then(response => {
                this.setState({ photos: response.data });
            })
            .catch(error => {
                NotificationManager.error(
                    "Ocorreu um erro ao carregar as fotos, tente novamente mais tarde",
                    "Ocorreu um erro",
                    5000
                );
            });
    };

    componentWillMount = () => {
        _api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token")).accessToken
                }`
        };
        this.setupPictures();
    };

    render() {
        return (
            <div className="fixed h-full">
                <Modal centered isOpen={this.state.deletePhotoModal}>
                    <ModalHeader>ETAPA DO CONTRATO</ModalHeader>
                    <ModalBody>
                        Tem certeza que deseja excluir esta foto?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={this.state.deletePhotoModalLoading}
                            className="btn btn-yellow"
                            style={buttonTheme}
                            onClick={() => {
                                this.deleteFile(this.state.deletePhotoID);
                            }}
                        >
                            Excluir
                        </Button>
                        <Button
                            disabled={this.state.deletePhotoModalLoading}
                            className="btn-hollow"
                            style={buttonTheme}
                            onClick={() => {
                                this.closeDeletePhotoModal();
                            }}
                        >
                            FECHAR
                        </Button>
                    </ModalFooter>
                </Modal>
                <NotificationContainer />                
                <Row style={{ marginBottom: 30 }}>                                                            
                    <Col md={{ size: 3, offset: 2 }}>
                        <p className="title">FOTOS DA OBRA</p>
                    </Col>
                    <Col md={{ size: 5 }} className="buttons-container">
                        <ButtonGroup>
                            <Button
                                className="btn btn-yellow"
                                style={buttonTheme}                                
                                onClick={() => {
                                    if(!this.state.uploading){
                                        document
                                            .querySelector("#filepicker")
                                            .click();
                                    }
                                }}
                                
                            >
                                {this.state.uploading ? (
                                <Spinner
                                style={{ width: "0.9rem", height: "0.9rem" }}                                
                                color="dark"
                                />
                            ) : "ADICIONAR FOTOS"}                                
                            </Button>
                            <Button
                                onClick={() => {
                                    this.toggleMode();
                                }}
                                className={`btn ${this.state.mode == 0 ? "btn-red" : ""
                                    }`}
                                style={buttonTheme}
                            >
                                {this.state.mode == 0
                                    ? "EXCLUIR FOTOS"
                                    : "CONCLUIR"}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {this.state.photos.length > 0 ? (
                    <Row className="photos-container">
                        <Col xs={{ size: 12 }}>
                            {this.state.photos.map((value, i) => {
                                return (
                                    <img
                                        key={i}
                                        id={value._id}
                                        onClick={e => {
                                            this.toggleClass(value);
                                        }}
                                        src={value.url}
                                    />
                                );
                            })}
                            {/* <img onClick={(e) => {this.toggleClass(e.target)}} src="https://imagens.canaltech.com.br/236607.471195-Lua.jpg"/>
                                <img onClick={(e) => {this.toggleClass(e.target)}} src="https://imagens.canaltech.com.br/236607.471195-Lua.jpg"/>
                                <img onClick={(e) => {this.toggleClass(e.target)}} src="https://imagens.canaltech.com.br/236607.471195-Lua.jpg"/>
                                <img onClick={(e) => {this.toggleClass(e.target)}} src="https://imagens.canaltech.com.br/236607.471195-Lua.jpg"/>
                                <img onClick={(e) => {this.toggleClass(e.target)}} src="https://imagens.canaltech.com.br/236607.471195-Lua.jpg"/> */}
                        </Col>
                    </Row>
                ) : (
                    <Row id="photos-container-no-items">
                        <strong>Adicionar fotos de suas obras</strong>
                    </Row>
                )}
                <div
                    className={`photo-visualizer ${this.state.photoToVisualize === "" ? "hidden" : ""
                        }`}
                >
                    <div
                        className="close-button"
                        onClick={() => {
                            this.setState({ photoToVisualize: "" });
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>
                    <img
                        src={
                            this.state.photoToVisualize === ""
                                ? ""
                                : `${this.state.photoToVisualize.url}`
                        }
                    />
                </div>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={e => {
                        this.setState({ uploading: true })
                        this.uploadFile([...e.target.files]);
                    }}
                    style={{
                        position: "absolute",
                        left: 9000000000000000,
                        opacity: 0
                    }}
                    id="filepicker"
                    multiple
                />
                <Row style={{ marginTop: 30 }}>
                    <Col md={{ size: 8, offset: 2 }}>
                        <div class="w-100">                            
                            <p class="disclaimer-font">*As fotos enviadas poderão ser utilizadas pela SH.</p>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BodyPictures;
