import React, { Component } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Form,
    FormGroup,
    Label
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import moment from "moment";
import "moment/locale/pt-br";
import { history } from "../../helper/history";

import "./styles.css";

import ReactGA from "react-ga";

export default class LoadUnloadReport extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/catalogos");
    }

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("token")).accessToken
            }`
        };
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );
        document.title = `SH - Catálogo`;
    };

    render() {
        return (
            <>
                <div className="catalog-container">
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header activeTab="catalog" />
                    <Row>
                        <Col xs={{ size: 6 }} md={{ size: 4, offset: 2 }}>
                            <a
                                href="https://drive.google.com/file/d/1aTuLtFmui3AJ5RiE6uuefh6mBBOqFFPS/view?usp=sharing"
                                className={`box normal`}
                                style={{
                                    backgroundImage: `url(${"./images/Pic-Catalogo_SH.jpg"})`
                                }}
                            >
                                <span className="title">Catálogo SH</span>
                            </a>
                            <a
                                href="https://drive.google.com/file/d/1S64_3ULtn4Cub4vCAgVpWTs-FYKxTh6M/view?usp=sharing"
                                className={`box normal`}
                                style={{
                                    backgroundImage: `url(${"./images/Pic-Cartilha-Indenizações.jpg"})`
                                }}
                            >
                                <span className="title">
                                    Cartilha Indenizações
                                </span>
                            </a>
                            <a
                                href="https://drive.google.com/file/d/1Xoly5kdLRUSUGF_FuK3Ap-5prQ2OUvFb/view?usp=sharing"
                                target="_blank"
                                className={`box normal`}
                                style={{
                                    backgroundImage: `url(${"./images/Pic-Cartilha-Andaimes.jpg"})`
                                }}
                            >
                                <span className="title">Cartilha Andaimes</span>
                            </a>
                            <a
                                href="https://drive.google.com/file/d/1Jnk0efsZEebp_dDpwgmniStR_M37lplV/view?usp=sharing"
                                target="_blank"
                                className={`box normal`}
                                style={{
                                    backgroundImage: `url(${"./images/Pic-Cartilha-Perfis.jpg"})`
                                }}
                            >
                                <span className="title">
                                    Perfis (Aço e Alumínio)
                                </span>
                            </a>
                        </Col>
                        <Col
                            className="second-column"
                            xs={{ size: 6 }}
                            md={{ size: 4 }}
                        >
                            <a
                                href="https://drive.google.com/file/d/11wVjusIfBg_h5_Gef1wM2kcguWnwfgRO/view?usp=sharing"
                                className={`box normal`}
                                style={{
                                    backgroundImage: `url(${"./images/Pic-Cartilha-Vigas-de-Madeira.png"})`
                                }}
                            >
                                <span className="title">
                                    Cartilha Vigas de Madeira
                                </span>
                            </a>
                            <a
                                href="https://drive.google.com/file/d/14GlTo6RqTFp7tKBjwjc0BtZ1QRXrcHT5/view?usp=sharing"
                                className={`box normal`}
                                style={{
                                    backgroundImage: `url(${"./images/Pic-Cartilha-Torres-de-Carga.jpg"})`
                                }}
                            >
                                <span className="title">
                                    Cartilha Torres de Carga
                                </span>
                            </a>
                            <a
                                href="https://drive.google.com/file/d/1BzxtV5mxhPrqx3R7OHwRCxyZKqy9x9Pn/view?usp=sharing"
                                target="_blank"
                                className={`box normal`}
                                style={{
                                    backgroundImage: `url(${"./images/Pic-Cartilha-Escoras.jpg"})`
                                }}
                            >
                                <span className="title">Cartilha Escoras</span>
                            </a>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
