import React from 'react'
import { FaAt, FaRss, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa'
import './styles.css'

const Icon = ({ name }) => {
  switch (name) {
    case 'at': return <FaAt className='icon' />
    case 'rss': return <FaRss className='icon' />
    case 'facebook': return <FaFacebook className='icon' />
    case 'linkedin': return <FaLinkedin className='icon' />
    case 'instagram': return <FaInstagram className='icon' />
    default: return null
  }
}

const MediaLink = ({ link, title, icon, bgColor, textColor }) => {

  const styles = {
    container: {
      backgroundColor: bgColor
    },
    text: {
      color: textColor
    }
  }

  return (
    <a href={link} target="_blank" className='container-media-link' style={styles.container}>
      <Icon name={icon} />
      <p className='title' style={styles.text}>{ title }</p>
    </a>
  )

}

export default MediaLink