import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    CardHeader,
    CardBody,
    InputGroup,
    InputGroupAddon,
    Input,
    Label
} from "reactstrap";
import Background from "../../components/background/Background";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import api from "./../../Services/_api.js";
import { history } from "../../helper/history";
import "./styles.css";

import ReactGA from "react-ga";

class Login_Token extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            login: "",
            token: "",
            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/LoginToken");
        this.handleSubmit = this.handleSubmit.bind(this);
        this.send_token_again = this.send_token_again.bind(this);
    }

    handleSubmit = async e => {
        e.preventDefault();
        await this.login();
    };

    send_token_again = async () => {
        var login = JSON.parse(localStorage.getItem('login'));
        await api
            .post("/token", login)
            .then(async response => {
                await localStorage.setItem("id", response.data.id);
                NotificationManager.success(
                    "Outro token foi enviado para você!",
                    "Sucesso",
                    5000
                );
            })
            .catch(e => {
                console.log(e);
            });
    };

    login = async () => {
        this.setState({ loading: true });
        await api
            .post(`/session`, {
                token: this.state.token,
                id: localStorage.getItem("id")
            })
            .then(async response => {
                await localStorage.setItem(
                    "_token",
                    JSON.stringify({
                        accessToken: response.data.token,
                        userId: localStorage.getItem("id")
                    })
                );
                this.complete_login();
            })
            .catch(error => {
                NotificationManager.error(
                    "Verifique se o token foi digitado corretamente",
                    "Token invalido",
                    5000
                );
                this.setState({ loading: false });
            });
    };

    complete_login = async () => {
        await localStorage.removeItem("login");
        await localStorage.removeItem("id");
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );

        // const firstAccess = localStorage.getItem("firstAccess")

        // if (firstAccess !== null) {
        //     this.setState({ redirect: "/home" });
        // } else {
        //     localStorage.setItem("firstAccess", true);

        //     this.setState({ redirect: "/user?firstaccess=1" });
        // }
        this.setState({ redirect: "/home" });

    };

    componentWillMount = async () => {
        if (
            (await localStorage.getItem("token")) &&
            (await localStorage.getItem("_token"))
        ) {
            this.setState({ redirect: "/home" });
        }

        const login = JSON.parse(await localStorage.getItem("login"));

        this.setState({
            login: JSON.parse(await localStorage.getItem("login"))
        });
        if (!login) {
            return this.setState({ redirect: "/" });
        }
        document.title = `SH - Token`;
    };

    componentDidMount = async () => {
        // Detect feature support via OTPCredential availability
        if ("OTPCredential" in window) {

            // Set up an AbortController to use with the OTP request
            const ac = new AbortController();
            // Request the OTP via get()
            navigator.credentials
                .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                })
                .then((otp) => {
                    // When the OTP is received by the app client, enter it into the form

                    console.error('otp promise concluded ' + otp);
                    // alert('otp promise concluded ' + otp);

                    this.setState({ token: otp.code });
                    console.error(otp.code);
                    // alert(otp.code);

                    const input = window.document.querySelector('input[autocomplete="one-time-code"]');
                    if (!input) return;

                    input.value = otp.code;
                })
                .catch((err) => {
                    console.error(err);
                    // alert(err);
                });
        }
    };
    // UNSAFE_componentWillMount = async () => {
    //     if (
    //         (await localStorage.getItem("token")) &&
    //         (await localStorage.getItem("_token"))
    //     )
    //         this.setState({ redirect: "/home" });

    //     this.setState({
    //         login: JSON.parse(await localStorage.getItem("login"))
    //     });
    //     if (!this.state.login) return this.setState({ redirect: "/" });
    //     document.title = `SH - Token`;
    // };

    render() {
        return (
            <>
                <NotificationContainer />
                <Background imagePath="https://kroonar.s3.amazonaws.com/sh_bg.gif" />
                {this.state.redirect !== "" ? (
                    <Redirect to={`${this.state.redirect}`} />
                ) : null}
                <div className="logintoken-container">
                    <Container>
                        <Row>
                            <Col
                                sm={{ size: 12 }}
                                md={{ size: 6, offset: 3 }}
                                xl={{ size: 4, offset: 4 }}
                            >
                                <Card>
                                    <CardHeader
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <img src={"./images/logo.svg"} />
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={this.handleSubmit}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <img
                                                        src={
                                                            "./icons/lock_icon.svg"
                                                        }
                                                        style={{
                                                            marginTop: "-13px",
                                                        }}
                                                    />
                                                </InputGroupAddon>
                                                <Label>
                                                    INSIRA O TOKEN
                                                </Label>
                                            </InputGroup>
                                            <InputGroup>
                                                <Input
                                                    required
                                                    autoComplete="one-time-code"
                                                    inputMode="numeric"
                                                    disabled={
                                                        this.state.loading
                                                    }
                                                    // type="tel"
                                                    type="text"
                                                    placeholder="000000"
                                                    value={this.state.token}
                                                    id="token-input"
                                                    onChange={e =>
                                                        this.setState({
                                                            token:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </InputGroup>
                                            <div className="login-links">
                                                <a
                                                    href='#t'
                                                    className="login-link"
                                                    onClick={
                                                        this.send_token_again
                                                    }
                                                >
                                                    Reenviar token
                                                </a>
                                                <a
                                                    href='#t'
                                                    className="login-link"
                                                    onClick={
                                                        () => history.push("/login-email")
                                                    }
                                                >
                                                    Tentar com e-mail
                                                </a>
                                            </div>
                                            <div
                                                className="button-Container"
                                            >
                                                <Button
                                                    type="submit"
                                                    block
                                                    id="submit-button"
                                                    disabled={this.state.loading}
                                                >
                                                    PROSSEGUIR
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
export default Login_Token;
