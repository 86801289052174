import React from "react";
import './styles.css';
// import './styles.scss';

export default function AgendamentosExample() {
    return (
        <div className="agendamentos-container">
            <div className="header-container">
                <div className="year-selection-container">
                    <select className="year-selector">
                        <option>2023</option>
                        <option selected>2024</option>
                        <option>2025</option>
                    </select>
                </div>
                <div className="month-selection-container">
                    <div className="month-control-container">
                        <div className="month-change-control">
                            <img src={"./icons/dashboard/arrow-left.svg"} />
                        </div>
                        <div className="month-name-big">
                            Outrubro
                        </div>
                        <div className="month-change-control">
                            <img src={"./icons/dashboard/arrow_right.svg"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="body-container">
                <div className="appointment-date-container">
                    <div className="date-container">
                        <div className="day-of-year-container">
                            QUA
                        </div>
                        <div className="day-of-month-container">
                            11
                        </div>
                    </div>
                    <div className="title-arrow-container">
                        <div className="appointment-title-container">
                            DEVOLUÇÃO - (11/10/2023)
                        </div>
                        <div className="arrow-container">
                            <img src={"./icons/dashboard/devolucao.svg"} />
                        </div>
                    </div>
                </div>
                <div className="appointment-date-container">
                    <div className="date-container">
                        <div className="day-of-year-container">
                            QUA
                        </div>
                        <div className="day-of-month-container">
                            11
                        </div>
                    </div>
                    <div className="title-arrow-container">
                        <div className="appointment-title-container">
                            RETIRADA - (11/10/2023)
                        </div>
                        <div className="arrow-container">
                            <img src={"./icons/dashboard/retirada.svg"} />
                        </div>
                    </div>
                </div>
                <div className="appointment-date-container">
                    <div className="date-container">
                        <div className="day-of-year-container">
                            QUA
                        </div>
                        <div className="day-of-month-container">
                            11
                        </div>
                    </div>
                    <div className="title-arrow-container">
                        <div className="appointment-title-container">
                            DEVOLUÇÃO - (11/10/2023)
                        </div>
                        <div className="arrow-container">
                            <img src={"./icons/dashboard/devolucao.svg"} />
                        </div>
                    </div>
                </div>
                <div className="appointment-date-container">
                    <div className="date-container">
                        <div className="day-of-year-container">
                            QUA
                        </div>
                        <div className="day-of-month-container">
                            11
                        </div>
                    </div>
                    <div className="title-arrow-container">
                        <div className="appointment-title-container">
                            DEVOLUÇÃO - (11/10/2023)
                        </div>
                        <div className="arrow-container">
                            <img src={"./icons/dashboard/devolucao.svg"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};