import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import './styles.css'

const Breadcrumbs = ({ items }) => {

  const isLastItem = index => {
    const lastIndex = items.indexOf(items[items.length - 1])
    if (lastIndex === index) return true
    else return false
  }

  return (
    <Breadcrumb>
      {
        items.map((item, index) => (
          <BreadcrumbItem>
            {
              isLastItem(index)
                ? <span>{ item.title }</span>
                : <a href={ item.link }>{ item.title }</a>
            }
          </BreadcrumbItem>
        ))
      }
    </Breadcrumb>
  )

}

export default Breadcrumbs