import React, { Component } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Form,
    FormGroup,
    Label
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import moment from "moment";
import "moment/locale/pt-br";
import { history } from "../../helper/history";

import "./styles.css";

import ReactGA from "react-ga";

export default class Showroom extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/showroom");
    }


    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );
        document.title = `SH - Showroom`;
    };

    render() {
        return (
            <>
                <div className="showroom-container">
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header activeTab="showroom" />

                    <Row>
                        <Col xs={{ size: 12 }} md={{ size: 4, offset: 2 }}>
                            <iframe src="https://sh.com.br/pt/showroom-sh/" />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
