import axios from "axios";

export default axios.create({
    // baseURL: "https://apidevdigital.sh.com.br"
    baseURL: "https://apisiclo.sh.com.br:4143" //production URL

    // baseURL: '4.228.108.110:4143', // newHomolBaseUrl
    // baseURL: "https://apitesteshdigital.sh.com.br:4143" // newHomolBaseUrl
    // baseURL: 'https://apishdigital.sh.com.br:4143', // newHomolBaseUrl
});
