import React from "react";
import Routes from "./routes";
import "./app.css";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';

ReactGA.initialize("UA-173154606-1");
TagManager.initialize({ gtmId: 'GTM-MK3P8B9' });

const App = () => (
    <div className="App">
        <Routes />
    </div>
);

export default App;
