import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import util from '../../util'
import api from '../../Services/api'
import './styles.css'

const ModalContractDetails = ({ modal, setModal, numeroProposta, anoProposta, nomeUnidade }) => {

  const [contractId, setContractId] = useState(util.queryParams().contract)
  const [contractDetails, setContractDetails] = useState({ emailVendedor: '', emailSac: '' })

  useEffect(() => {
    const getContractDetails = async (id) => {
        const promises = [api.get(`/api/contrato/${id}/detalhes`)]
        await Promise.all(promises)
        .then(response => {
            for(let x = 0; x < response.length; x++) {
                let resp = response[x]
                switch(x) {
                    case 0:
                      setContractDetails(resp.data[0])
                    break;
                }
            }
        })
    }
    api.defaults.headers.common = { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}` }
    getContractDetails(contractId)
  }, [contractId])

  const showValue = value => {
    if (contractDetails && contractDetails[`${value}`]) return contractDetails[`${value}`]
    else return '-'
  }

  return (
    <Modal isOpen={modal} toggle={() => setModal()} id='modal-contract-details'>
      <ModalHeader toggle={() => setModal()}>
        <span id='contract-details-header'>Detalhes do Contrato</span>
      </ModalHeader>
      <ModalBody>
        <Row className="details">
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">Número</span><br/>
                <span className="value">{numeroProposta ? numeroProposta.toString().padStart(4, "0") : ""}/{anoProposta}-{nomeUnidade}</span>
                <hr/> 
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">Contratante</span><br/>
                <span className="value">{showValue('nomeContratante')}</span>   
                <hr/> 
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">Cliente</span><br/>
                <span className="value">{showValue('nomeCliente')}</span>  
                <hr/> 
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">CNPJ Cliente</span><br/>
                <span className="value">{showValue('cnpjCliente')}</span> 
                <hr/> 
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">Gerente da Unidade</span><br/>
                <span className="value">{showValue('gerenteUnidade')}</span>  
                <hr/> 
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">Comercial</span><br/>
                <span className="value">{showValue('nomeVendedor')}</span><br/>
                <a href={`mailto:${contractDetails.emailVendedor}`} className="value">
                  {showValue('emailVendedor')}
                </a>
                <hr/> 
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">Assistente Técnico</span><br/>
                <span className="value">{showValue('asTec')}</span><br/>
                <a href={`mailto:${contractDetails.emailAsTec}`} className="value">
                  {showValue('emailAsTec')}
                </a>
                <hr/> 
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">Resp. Logística</span><br/>
                <span className="value">{showValue('funcLogistica')}</span><br/>
                <a href={`mailto:${contractDetails.emailFuncLogistica}`} className="value">
                  {showValue('emailFuncLogistica')}
                </a>
                <hr/>
            </Col>
            <Col xs={{size: 12}} md={{size: 8, offset: 2}}>
                <span className="label">SAC</span><br/>
                <a href={`mailto:${contractDetails.emailSac}`} className="value">
                  {showValue('emailSac')}
                </a>  
            </Col>
          </Row>
      </ModalBody>
    </Modal>
  )

}

export default ModalContractDetails